<template>
  <div class="main">
    <div style="grid-area: header">
    </div>
    <div style="grid-area: left">
      <ContentParagraph :text="text"/>
    </div>
    <div style="grid-area: right">
      <img :src="require(`@/assets/images/F6DA1E65-E00D-48AB-9474-B82E7A2585CE.jpg`)"
           style="height: auto; width: 80%; min-width: 15rem;"
           alt="parental"/>
    </div>
    <div style="grid-area: footer">
      <InfoForm/>
    </div>
  </div>
</template>

<script>
import ContentParagraph from "@/components/ContentParagraph";
import InfoForm from "@/components/InfoForm";

export default {
  name: "ParentalGuidance",
  components: {InfoForm, ContentParagraph},
  data() {
    return {
      showPreview: true,
      text: "<h2>הדרכת הורים</h2>" +
          "הורים יקרים, כמה פעמים הרגשתם תקיעות בתקשורת עם הילדים שלכם וביחסים איתם?\n" +
          "אתם מוצאים את עצמכם עייפים ומותשים, נאבקים על המקום שלכם בין אם אתם הורים לפעוטות, ילדים או מתבגרים. אתם לא צריכים להתמודד עם זה לבד, בשביל זה אני כאן.\n" +
          "המטרה שלי בתהליכים שאני עושה היא לגרום לכם להרגיש שלמים עם ההורות שלכם. להפוך להיות מ-נשלטים ל-מובילים את הסיטואציות המורכבות שאתם מתמודדים איתם ביום יום שלכם.\n" +
          "\n" +
          "על פי איזו גישה אני עובדת? הגישה שאני משתמשת בה בהדרכות שאני מעבירה מתבססת על גישת אדלר. אדלר דגל בהוליזם - כלומר השינוי שנעשה במהלך התהליך יהיה הוליסטי, שינוי במערכת המשפחתית כולה ולא שינוי בילד. נבין את תפיסת הילד, כיצד הוא חושב ומה גורם לו לבחור באותה התנהגות המפריעה לכם ההורים, דרך ההתבוננות נלמד לשנות את האווירה המשפחתית בבית. \n" +
          "אני מזמינה אתכם להוביל לשינוי במשפחה שלכם עבורכם ועבור הילדים שלכם.\n",
    }
  }
}
</script>

<style scoped>
.main {
  z-index: 10 !important;
  top: 0;
  display: grid;
  grid-template-areas:
    'header header header header header header'
    'left left left right right right'
    'footer footer footer footer footer footer';
  gap: 10px;
  padding: 10px;
  width: 80%;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .main {
    grid-template-areas:
    'header header header header header header'
    'left left left left left left'
    'right right right right right right'
    'footer footer footer footer footer footer';
    gap: 10px;
    padding: 10px;
    width: 100%
  }
}
</style>
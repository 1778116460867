<template>
  <template v-if="hasPreview">
    <div>
      <p>
    <span v-for="(p, index) in previewText.split('\n')" :key="index">
      <span v-html="p"/>
      <br>
    </span>
      </p>
    </div>
    <div :class="previewClass">
      <span v-for="(p, index) in text.split('\n')" :key="index">
        <span v-html="p"/>
      <br>
    </span>
    </div>
    <CTAButton :label="buttonLabel" @click="togglePreview"/>
  </template>
  <template v-else>
    <div>
      <span v-for="(p, index) in text.split('\n')" :key="index">
    <span v-html="p"/>
    <br>
    </span>
    </div>
  </template>
  <br>
</template>

<script>
import CTAButton from "@/components/CTAButton";
export default {
  name: "ContentParagraph",
  components: {CTAButton},
  data() {
    return {
      showPreview: true,
    }
  },
  props: {
    hasPreview: {
      type: Boolean,
      default: false,
    },
    previewText: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonLabel() {
      return this.showPreview ? "לקריאה נוספת" : "הסתר"
    },
    previewClass() {
      return this.showPreview ? "inf_o collapse" : "inf_o"
    }
  },
  methods: {
    togglePreview() {
      this.showPreview = !this.showPreview;
    }
  }
}
</script>

<style scoped>
div {
  font-size: 28px;
  line-height: 1.5em;
  text-align: right;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 640px) {
  div {
    max-width: 100%;
    font-size: 25px;
  }
}

.inf_o {
  overflow: hidden;
  transition: max-height 1.5s;
  max-height: 1400px;
}

.collapse {
  max-height: 1px;
}

@-webkit-keyframes lower_head
{
  from {margin-top:0px;}
  to {margin-top:10px;}
}

</style>
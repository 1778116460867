<template>
  <br>
<div class="footer">
  <a class="arrow" @click="visible = true">הצהרת נגישות</a>
  <Dialog v-model:visible="visible" modal header="הצהרת נגישות" :style="{ width: '50rem', textAlign: 'right' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <p class="mb-5">
      אני רואה חשיבות רבה במתן שירות שוויוני לכלל הלקוחות והגולשים ובשיפור השירות הניתן ללקוחות ולגולשים עם מוגבלות.
      לכן אני משקיעה משאבים רבים בהנגשת האתר שלי על מנת להפוך אותו לזמין יותר עבור אנשים עם מוגבלות.
    </p>
    <h3>נגישות אתרי אינטרנט</h3>
    <p class="mb-5">
      הנגשת האתר נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אנשים עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות, לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אנשים בני הגיל השלישי.
      אני עושה מאמצים שהאתר שלי יעמוד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע"ג 2013, ברמת התקן הנדרש. כמו כן, אני מיישמת את המלצות מסמך WCAG2.0 מאת ארגון -W3C
    </p>
    <h3>תיקונים והתאמות שבוצעו</h3>
    <p class="mb-5">
      <ul dir="rtl">
    <li>האתר מותאם לדפדפנים הנפוצים</li>
    <li>האתר מותאם לשימוש בטלפון הסלולרי</li>
    <li>האתר מותאם לתצוגה תואמת מגוון מסכים ורזולוציות</li>
    <li>אמצעי הניווט באתר פשוטים וברורים</li>
    <li>אמצעי הניווט באתר פשוטים וברורים</li>
    <li>כל הדפים באתר בעלי מבנה קבוע</li>
    <li>למרבית התמונות באתר יש הסבר טקסטואלי חלופי (alt)</li>
    <li>האתר מאפשר שינוי גודל הגופן על ידי שימוש במקש Ctrl ומקש + במקלדת</li>
    <li>אין באתר שימוש בתצוגת טקסט נע או מהבהב</li>
    <li>באתר מותקן תוסף נגישות המאפשר הגדלת והקטנת טקסט, שינוי ניגודיות, סימון כותרות ועוד</li>
  </ul>
    </p>
    <p class="mb-5">
      חשוב לציין, כי למרות המאמצים להנגיש את כלל הדפים באתר, ולמרות פעילותי לשפר את נגישות האתר – ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.
      אם נתקלתם בבעיה בנושא נגישות באתר, אשמח אם תכתבו אלי באמצעות פנייה אלי למייל<br> stephanieeival@gmail.com
    </p>
    <p class="mb-5">
      כדי שאוכל לטפל בבעיה בדרך הטובה ביותר, אני ממליצה מאוד לצרף פרטים מלאים ככל שניתן:
<br>
    <ul dir="rtl">
        <li>תיאור הבעיה</li>
        <li>מהי הפעולה שניסיתם לבצע</li>
        <li>מהי הפעולה שניסיתם לבצע</li>
        <li>קישור לדף בו גלשתם</li>
        <li>סוג הדפדפן וגרסתו</li>
        <li>מערכת הפעלה</li>
        <li>סוג הטכנולוגיה המסייעת (במידה והשתמשתם)</li>
      </ul>
    <br>
      אני אעשה ככל שביכולתי על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות בצורה המקצועית והמהירה ביותר.
    </p>
    <h3>פרטי רכז הנגישות</h3>
    <p>
      שם: עומרי עיבל<br>
      דוא”ל: omrieival@gmail.com<br>
      הצהרת הנגישות עודכנה ביום: 16.02.2024<br>
    </p>
  </Dialog>

</div>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
  name: "SiteFooter",
  components: {
    Dialog,
  },
  data() {
    return {
      visible: false,
    }
  }
}
</script>

<style scoped>
.footer {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  direction: rtl;
}
</style>
<template>
  <ContentParagraph :text="text"
                    :preview-text="previewText"
                    :has-preview="true"
  />
</template>

<script>
import ContentParagraph from "@/components/ContentParagraph";

export default {
  name: "AboutMe",
  components: {ContentParagraph},
  data() {
    return {
      showPreview: true,
      previewText: "נעים מאוד להכיר, שמחה שאתם כאן, אספר לכם קצת על עצמי ועל המומחיות שלי. \n" +
          "שמי סטפני עיבל בת 32 נשואה לעומרי ואמא לאמילי ויונתן.\n" +
          "תמיד ידעתי שיש בי אהבה ותשוקה לעולם הילדים, קראתי ולמדתי על התחום המרתק הזה,\n" +
          "אך המציאות שלי כיוונה אותי מקצועית לכיוון אחר.",
      text: " מתי הדברים השתנו? כשאמילי שלי נולדה. הבנתי כמה שאני נהנית מההורות שלי וכמה הלמידה שלי במרוצות השנים עזרו לי להתנהל בתא המשפחתי שלי ועדיין נתקלתי באתגרים לא קלים בהורות והרגשתי שחסרים לי כלים להתמודדות ולשיפור היחסים בבית. החלטנו כמשפחה שאני עוזבת את עולם ההייטק ויוצאת לדרך חדשה ועצמאית במטרה להיות אדם שלם יותר עבורי ועבור הילדים שלי ולהעביר את הכל הלאה להורים אחרים. \n" +
          "במקביל ללימודים שלי ב׳בית למקצועות ההורות׳ בגישת אדלר עבדתי במסגרות פרטיות בגני ילדים ובמסגרות חינוכיות בבתי ספר. בעיני ההורות הוא תפקיד חיי והתשוקה שלי מכוונת לשם. להיות הורה זה אתגר לא קטן. אף אחד לא מלמד אותנו להיות הורים.\n" +
          "\n" +
          "<b>״בואו לגלות את ההורות שלכם מחדש יחד איתי״</b>"
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <Toast />
  <div class="form-wrapper">
    <Panel>
      <h1><u>יצירת קשר</u></h1>
      <h2>השאירו פרטים ואחזור אליכם בהקדם</h2>
      <div class="form">
        <div class="flex flex-column gap-3">
          <div class="card flex flex-row gap-3">
              <FloatLabel>
                <InputText id="name" v-model="name" :invalid="nameInvalid" @input="checkName"/>
                <label for="name">שם</label>
              </FloatLabel>
              <FloatLabel>
                <InputText id="phone" v-model="phone" :invalid="phoneInvalid" @input="checkPhone" />
                <label for="phone">טלפון</label>
              </FloatLabel>
          </div>
        </div>
        <br>
        <div class="flex flex-column gap-3">
          <FloatLabel>
            <InputText id="email" v-model="email" :invalid="emailInvalid" @input="checkEmail"/>
            <label for="email">כתובת מייל</label>
          </FloatLabel>
        </div>
        <br>
        <label>נושא הפניה</label>
        <br><br>
        <div class="flex align-items-center">
          <RadioButton v-model="reason" inputId="parental" name="parental" value="parental" />
          <label for="parental" class="ml-2 radio-label">הדרכת הורים</label>
        </div>
        <br>
        <div class="flex align-items-center">
          <RadioButton v-model="reason" inputId="sleep" name="sleep" value="sleep" />
          <label for="sleep" class="ml-2 radio-label">ייעוץ שינה</label>
        </div>
      </div>
      <CTAButton label="שליחה" @click="onSubmit" :prevent-submit="preventSubmit"/>
    </Panel>
  </div>
</template>

<script>

import FloatLabel from 'primevue/floatlabel';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Panel from 'primevue/panel';
import Toast from 'primevue/toast';
import CTAButton from "@/components/CTAButton";

const TOKEN = "qd0XR-4f0Dx8MLDe2GhcJVFQyNQ8dMam8XL5unp_OUE";
const WH_URL = "https://hooks.torq.io/v1/webhooks/96a2cbe7-bad5-4247-ade1-8e68e87b8ee9";

export default {
  name: "InfoForm",
  components: {
    FloatLabel,
    InputText,
    RadioButton,
    Panel,
    Toast,
    CTAButton,
  },
  data() {
    return {
      name: "",
      nameInvalid: false,
      phone: "",
      phoneInvalid: false,
      email: "",
      emailInvalid: false,
      reason: "",
      submitted: false,
      dialogVisible: true,
    }
  },
  computed: {
    preventSubmit() {
      return this.submitted ||
          this.nameInvalid || (this.phoneInvalid || this.emailInvalid) ||
          this.name === "" ||
          (this.phone === "" && this.email === "");
    }
  },
  methods: {
    checkName() {
      this.nameInvalid = this.name === "";
    },
    checkPhone() {
      this.phoneInvalid = this.phone !== "" && !this.phone.match(/\+?[0-9]{3}?-?[0-9]{7,10}/)
    },
    checkEmail() {
      this.emailInvalid = this.email !== "" && !this.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
    },
    clearForm() {
      this.email = ""
      this.phone = ""
      this.reason = ""
      this.name = ""
      this.emailInvalid = false;
      this.nameInvalid = false;
      this.phoneInvalid = false;
    },
    onSubmit() {
      if (this.nameInvalid || (this.phoneInvalid && this.emailInvalid)) {
        return
      }
      const requestOptions = {
        method: "POST",
        mode: 'no-cors',
        headers: { "content-type": "application/json", "x-token": TOKEN },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          phone: this.phone,
          reason: this.reason,
        })
      };
      fetch(WH_URL, requestOptions).catch((reason) => {console.log(reason)}).then(() => {
        this.clearForm();
        this.dialogVisible = true;
      });

      this.submitted = true;
      this.$toast.add({ severity: 'success', summary: 'תודה רבה', detail: 'אצור קשר בקרוב!', life: 5000, styleClass: "he" });
    },
  }

}
</script>

<style scoped>

.form-wrapper {
  color: #114b5f !important;
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
  width: 100%;
  justify-content: center;
}

.form {
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 10px;
  display: block;
  text-align: right;

}

.p-float-label .p-inputtext{
  display: block;
  width: 100%;
}

.p-float-label > label{
  right: 0.75rem;
}

.radio-label {
  margin-right: 8px;
}

.p-panel {
  color: #114b5f !important;
  border-color: #114b5f;
  border-width: 2px;
  max-width: 100rem;
  min-width: 20rem;
}

.he {
  direction: rtl !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.flex-row .p-float-label {
  width: 100%;
}

div.p-panel-content {
  color: #114b5f !important;
}
</style>
<template>
  <TabMenu :model="items"/>
  <div class="container">
  </div>
  <div class="body">
    <component :is="pages[this.selectedPage]"/>
  </div>
  <SiteFooter/>
</template>

<script>
import MainPage from './pages/Main.vue'
import 'primeicons/primeicons.css'
import InfoForm from "@/components/InfoForm";
import SiteFooter from "@/components/SiteFooter";
import TabMenu from 'primevue/tabmenu';
import {ref} from "vue";
import ParentalGuidance from "@/pages/ParentalGuidance";
import SleepTraining from "@/pages/SleepTraining";


export default {
  name: 'App',
  components: {
    SiteFooter,
    MainPage,
    InfoForm,
    TabMenu,
  },
  data() {
    return {
      items: ref([
        {label: 'ראשי', command: () => {this.menuSelect("mainPage")} },
        {label: 'הדרכת הורים', command: () => {this.menuSelect("parentalGuidance")} },
        {label: 'ייעוץ שינה', command: () => {this.menuSelect("sleepTraining")} },
        //{label: 'מאמרים וסרטונים', command: () => {this.menuSelect("articles")} },
        {label: 'צרו קשר', command: () => {this.menuSelect("contact")} }
      ]),
      selectedPage: "mainPage",
      pages: {
        mainPage: MainPage,
        contact: InfoForm,
        parentalGuidance: ParentalGuidance,
        sleepTraining: SleepTraining,
      }
    }
  },
  mounted() {
    document.title = 'סטפני עיבל - הדרכת הורים וייעוץ שינה'
    this.menuSelect("mainPage")
  },
  methods: {
    menuSelect(label) {
      console.log(label);
      this.selectedPage = label;
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
body {
  background-color: #fff8f3;
  z-index: 1;
}

.container {
  background-image: url("./assets/images/hands2.png");
  background-repeat: no-repeat;
  z-index: -1;
  min-width: 100vw;
  min-height: 100%;
  object-fit: cover;
  padding: 0;
  top: 0;
  left: 0;
  position: fixed;
  background-size: 100% 500px;
  background-attachment: fixed;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .container {
    background-image: url("./assets/images/hands2.png");
    background-repeat: no-repeat;
    z-index: -1;
    min-width: 100vw;
    min-height: 100%;
    object-fit: cover;
    padding: 0;
    top: 0;
    left: 0;
    position: fixed;
    background-size: 100% 500px;
    background-attachment: fixed;
    overflow: hidden;
  }

}

.p-tabmenu {
  width: 100%;
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: #114b5f;
}

.p-tabmenu .p-tabmenu-ink-bar {
  background-color: #114b5f;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #114b5f;
  direction: rtl;
}

.body {
  z-index: 10 !important;
  top: 0;
  padding: 10px;
  margin-top: 65px;
}
</style>

<template>
  <div class="main">
    <div style="grid-area: header">
    </div>
    <div style="grid-area: left" class="text">
        <ContentParagraph :text="text"/>
    </div>
    <div style="grid-area: right">
      <img :src="require(`@/assets/images/DSC_3155.jpg`)"
           style="height: auto; width: 100%; min-width: 15rem;"
           alt="sleep"/>
    </div>
    <div style="grid-area: footer">
      <InfoForm/>
    </div>
  </div>
</template>

<script>
import ContentParagraph from "@/components/ContentParagraph";
import InfoForm from "@/components/InfoForm";

export default {
  name: "SleepTraining",
  components: {InfoForm, ContentParagraph},
  data() {
    return {
      showPreview: true,
      text: "<h2>ייעוץ שינה בשיטת 'לילה טוב'</h2>" +
          "<h3>מגיל 5 חודשים - 5 שנים</h3>" +
          "<u>העקרונות המרכזיים בשיטת 'לילה טוב':</u>\n" +
          "- מענה תמידי לבכי הילד לכל אורך התהליך.\n" +
          "- הירדמות עצמאית ושינה רציפה.\n" +
          "- מענה לכל צורך אחר של הילד שיכול להשפיע על איכות השינה.\n" +
          "- תוכנית מותאמת באופן אישי לצרכי הילד והמשפחה - כל תהליך שינה בנוי בצורה אחרת.\n" +
          "- תהליך שבנוי בצורה הדרגתית על מנת לבנות את הביטחון של הילד בהוריו.\n" +
          "\n" +
          "בתהליך ייעוץ שינה אנחנו לא נדבר רק על השינה של הילד, אלא נעסוק גם בהיבטים נוספים הקשורים בדינמיקה המשפחתית, בכם ההורים ובשאר הנפשות במשפחה. הכלים שתקבלו יעזרו לכם גם בהיבט השינה וגם בסיטואציות נוספות בהורות שלכם.\n",
    }
  }
}
</script>

<style scoped>
div.text {
  font-size: 28px;
  line-height: 1.5em;
  max-width: 100%;
  text-align: right;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 640px) {
  div.text {
    max-width: 100%;
    font-size: 25px;
  }
  h1 {
    font-size: 28px;
  }
}

.main {
  z-index: 10 !important;
  top: 0;
  display: grid;
  grid-template-areas:
    'header header header header header header'
    'left left left right right right'
    'footer footer footer footer footer footer';
  gap: 10px;
  padding: 10px;
  width: 80%;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .main {
    grid-template-areas:
    'header header header header header header'
    'left left left left left left'
    'right right right right right right'
    'footer footer footer footer footer footer';
    gap: 10px;
    padding: 10px;
    width: 100%
  }
}
</style>
<template>
<Button size="large" :label="label" @click="onSubmit" :disabled="preventSubmit"/>
</template>

<script>
import Button from "primevue/button";

export default {
  name: "CTAButton",
  props: {
    label: {
      type: String,
    },
    onSubmit: {
      type: Function,
    },
    preventSubmit: {
      type: Boolean,
    }
  },
  components: {
    Button,
  },
}
</script>

<style scoped>
button {
  background-color: #3C6B86;
  border: 1px solid black;
  color: #FFD7C8;
}
</style>